export function product(state = { hidden: false, products:[], recordsTotal:0 }, action) {
  switch (action.type) {
    case "FETCH_PRODUCT":
      return {
        ...state,
        products: action.payload.products,
        pageCount: action.payload.pageCount,
        offset: action.payload.offset,
        recordsTotal: action.payload.recordsTotal,
        hidden: false,
        errors: {}
      };

    case 'DELETE_PRODUCT': {
      const _id = action.payload._id;
      return {
        ...state,
        products: state.products.filter(item => item._id !== _id),
        delete: action.payload,
        recordsTotal: parseInt(state.recordsTotal) - 1,
        pageCount: Math.ceil((state.recordsTotal - 1) / 2)
      }
    }
    default:
      return state
  }
}
