

export function dashboard(state = {}, action) {
  switch (action.type) {
    case "FETCH_CUSTOMER_COUNT":
      return {
        ...state,
        totalCustomer: action.payload.data.totals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      };
    case "FETCH_PRODUCT_COUNT":
      return {
        ...state,
        totalProduct: action.payload.data.totals.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      };
    case "FETCH_CHART_DATA":
      return {
        ...state,
        chartData: action.payload
      };
    case "FETCH_EXCEL_DATA":
      return {
        ...state,
        report: action.payload
      };
    case "FETCH_PRODUCT_REPORT":
      return {
        ...state,
        productReport: action.payload
      };
    case "PRODUCT_PIE_DATA":
      return {
        ...state,
        pieData: action.payload
      };

    case "FETCH_BROADCAST":
      return {
        ...state,
        broadcastReport: action.payload
      };

    case "BROADCAST_TOTAL":
      return {
        ...state,
        broadcastTotalLast7Days: action.payload
      };

    case "CHART_BROADCAST":
      return {
        ...state,
        chartBroadcastData: action.payload
      };
    case "FETCH_MAXIMUM_MESSAGE":
      return {
        ...state,
        broadcast: action.payload
      };

    default:
      return state
  }
}
