import update from 'immutability-helper';
import React from 'react';
import './Chips.scss';

export const Chips = ({ children, ...rest }) => {
  return (
    <>
      <CustomChips {...rest}>{children}</CustomChips>
    </>
  );
};

export class CustomChips extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chips: [],
      maxCount: 10,
      maxLengthOfChip: 10,
      placeholder: props.placeholder ? props.placeholder : '',
      isSaveChip: false,
      listenKeyDownEvents: false,
      showDuplicateError: false,
      allowSpecialChars: false,
      error: [],
    };
    this.inputRef = React.createRef();
    this.clearInvalidChars = this.clearInvalidChars.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.updateInputWidth = this.updateInputWidth.bind(this);
    this.getWidth = this.getWidth.bind(this);
    this.updateChips = this.updateChips.bind(this);
    this.deleteChip = this.deleteChip.bind(this);
    this.onAddButtonClick = this.onAddButtonClick.bind(this);
    this.updateDuplicateError = this.updateDuplicateError.bind(this);
  }

  componentDidMount() {
    this.setDefaultState(this.props);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isSaveChip !== this.props.isSaveChip ||
      prevProps.chips?.length !== this.props.chips?.length
    ) {
      this.setDefaultState(this.props);
    }
  }

  setDefaultState(props) {
    if (props) {
      this.setState({
        chips: props.chips && props.chips.length ? props.chips : [],
      });
      this.setState({ maxCount: props.maxCount ? props.maxCount : 10 });
      this.setState({
        maxLengthOfChip: props.maxLengthOfChip ? props.maxLengthOfChip : 10,
      });
      this.setState({
        placeholder: props.placeholder ? props.placeholder : '',
      });
      this.setState({
        showDuplicateError: props.showDuplicateError
          ? props.showDuplicateError
          : false,
      });
      this.setState({
        listenKeyDownEvent: props.listenKeyDownEvent
          ? props.listenKeyDownEvent
          : false,
      });
      this.setState({
        allowSpecialChars: props.allowSpecialChars
          ? props.allowSpecialChars
          : false,
      });
      this.setState({
        inpuStyle: { width: '50px' },
      });

      if (props.isSaveChip) {
        this.onAddButtonClick();
      }
    }
  }

  onAddButtonClick() {
    if (!this.state.maxCount || this.state.chips.length < this.state.maxCount) {
      let value = this.inputRef.current.value;
      if (!value) return;
      let chip = value.trim();

      if (
        chip &&
        this.state.chips.length &&
        this.state.chips.indexOf(chip) > -1 &&
        this.state.showDuplicateError
      ) {
        this.setState(
          {
            chips: update(this.state.chips, {
              $push: [chip],
            }),
          },
          () => {
            this.props.updateChipsStateEvent(this.state.chips);
            this.props.updateChipSaveEvent();
            this.updateDuplicateError();
          },
        );
      } else if (chip) {
        this.setState(
          {
            chips: update(this.state.chips, {
              $push: [chip],
            }),
          },
          () => {
            this.props.updateChipsStateEvent(this.state.chips);
            this.props.updateChipSaveEvent();
            this.updateDuplicateError();
          },
        );
      } else {
        this.props.updateChipSaveEvent();
      }
    }
    this.inputRef.current.value = '';
  }

  updateDuplicateError() {
    const duplicateExists = this.state.chips.some(
      (chip, index) => this.state.chips.indexOf(chip) !== index,
    );
    this.props.updateDuplicateChipErrorEvent(duplicateExists);
  }

  onKeyDown(event) {
    this.updateInputWidth(event);
    if (!this.state.listenKeyDownEvents) {
      return;
    }
    let keyPressed = event.which;
    let KEY = {
      backspace: 8,
      tab: 9,
      enter: 13,
    };
    if (
      keyPressed === KEY.enter ||
      (keyPressed === KEY.tab && event.target.value)
    ) {
      event.preventDefault();
      this.updateChips(event);
    } else if (keyPressed === KEY.backspace) {
      let chips = this.state.chips;
      if (!event.target.value && chips.length) {
        this.deleteChip(chips[chips.length - 1]);
      }
    }
  }

  clearInvalidChars(event) {
    let value = event.target.value;
    let INVALID_CHARS = /[^a-zA-Z0-9 ]/;
    this.updateInputWidth(event);

    if (this.state.allowSpecialChars && INVALID_CHARS.test(value)) {
      event.target.value = value.replace(INVALID_CHARS, '');
    } else if (value.length > this.state.maxLengthOfChip) {
      event.target.value = value.substr(0, this.state.maxLengthOfChip);
    }
  }

  updateInputWidth(event) {
    let value = event.target.value;
    value = value.trim();

    this.setState({
      inpuStyle: { width: this.getWidth(value) + 'px' },
    });
  }

  getWidth(value) {
    let width = 50;
    if (value && value.length) {
      width = value.length * 12 > 50 ? value.length * 12 : width;
    }
    return width;
  }

  updateChips(event) {
    if (!this.state.maxCount || this.state.chips.length < this.state.maxCount) {
      let value = event.target.value;
      if (!value) return;
      let chip = value.trim();

      if (
        chip &&
        this.state.chips.length &&
        this.state.chips.indexOf(chip) > -1 &&
        this.state.showDuplicateError
      ) {
        this.updateDuplicateError();
      } else if (chip) {
        this.setState(
          {
            chips: update(this.state.chips, {
              $push: [chip],
            }),
          },
          () => {
            this.props.updateChipsStateEvent(this.state.chips);
            this.updateDuplicateError();
          },
        );
      }
    }
    event.target.value = '';
  }

  deleteChip(chip) {
    let index = this.state.chips.indexOf(chip);
    if (index >= 0) {
      this.setState(
        {
          chips: update(this.state.chips, {
            $splice: [[index, 1]],
          }),
        },
        () => {
          this.props.updateChipsStateEvent(this.state.chips);
          this.updateDuplicateError();
        },
      );
    }
  }

  focusInput(event) {
    let children = event.target.children;
    if (children.length) children[children.length - 1].focus();
  }

  render() {
    const { chips, inpuStyle, maxLengthOfChip } = this.state;
    let chipsView = chips.map((chip, index) => {
      return (
        <span className="chip" key={index}>
          <span className="chip-value">{chip}</span>
          <button
            type="button"
            className="chip-delete-button"
            onClick={this.deleteChip.bind(null, chip)}
          >
            x
          </button>
        </span>
      );
    });

    let placeholder =
      !this.state.maxCount || chips.length < this.state.maxCount
        ? this.state.placeholder
        : '';

    return (
      <div className="chips" onClick={this.focusInput}>
        {chipsView}
        <input
          type="text"
          className="chips-input"
          maxLength={maxLengthOfChip}
          style={inpuStyle}
          ref={this.inputRef}
          placeholder={placeholder}
          onKeyDown={this.onKeyDown}
          onKeyUp={this.clearInvalidChars}
        />
      </div>
    );
  }
}
